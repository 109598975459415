@font-face {
  font-family: "PlusJakartaSans-Regular";
  src: local("PlusJakartaSans"),
    url(../fonts/PlusJakartaSans-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "PlusJakartaSans-Medium";
  src: local("PlusJakartaSans"),
    url(../fonts/PlusJakartaSans-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "PlusJakartaSans-Bold";
  src: local("PlusJakartaSans"),
    url(../fonts/PlusJakartaSans-Bold.ttf) format("truetype");
}

.custom-toast {
  width: initial !important;
}

.success-toast {
  border-radius: 4px;
  border: 1px solid #057133;
  background: #f2fdf6;
  padding: 4px 12px;
  color: #033a19;
  font-family: "PlusJakartaSans-Regular";
  min-height: 36px;
}

.error-toast {
  border-radius: 4px;
  border: 1px solid #710c05;
  background: #fffafa;
  padding: 4px 12px;
  color: #710c05;
  font-family: "PlusJakartaSans-Regular";
  min-height: 36px;
}
