@font-face {
  font-family: "OpenSans-Regular";
  src: local("OpenSans"), url(../fonts/OpenSans-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "OpenSans-Medium";
  src: local("OpenSans"), url(../fonts/OpenSans-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "OpenSans-Bold";
  src: local("OpenSans"), url(../fonts/OpenSans-Bold.ttf) format("truetype");
}
