body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1920px;
  margin: 0 auto !important;
  float: none !important;
}
.hide_scroll {
  overflow: hidden;
}
::-webkit-scrollbar {
  width: 0.3em;
  height: 0.4rem;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 20px;
}
